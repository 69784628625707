import { useEffect, useState } from 'react'

/**
 * Modified from link below
 * @see https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
 * @param mediaQueryString
 * @returns {unknown}
 */
export default function useBetterMediaQuery(mediaQueryString: string) {
    const [matches, setMatches] = useState < boolean > ()

    useEffect(() => {
        const mediaQueryList = window.matchMedia(mediaQueryString)
        const listener = () => setMatches(!!mediaQueryList.matches)
        listener()
        mediaQueryList.addEventListener('change', listener) // updated from .addListener
        return () => mediaQueryList.removeEventListener('change', listener) // updated from .removeListener
    }, [mediaQueryString])

    return matches
}
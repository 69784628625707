// DualRolodexText.tsx
"use client";
import React, { useState, useEffect } from 'react';
import './Roladex.css';

interface DualRolodexTextProps {
    textItemsA: string[];
    textItemsB: string[];
    interval: number;
    displayTime: number; // Time text stays on screen
}

const DualRolodexText: React.FC<DualRolodexTextProps> = ({ textItemsA, textItemsB, interval, displayTime }) => {
    const [currentIndexA, setCurrentIndexA] = useState(0);
    const [currentIndexB, setCurrentIndexB] = useState(0);
    const [animateA, setAnimateA] = useState('top-in');
    const [animateB, setAnimateB] = useState('bottom-in'); // Change this to 'bottom-in' to match the initial animation direction

    useEffect(() => {
        const animationInterval = interval + displayTime;

        const switchTextA = setInterval(() => {
            setAnimateA('top-out');
            setTimeout(() => {
                setCurrentIndexA((prevIndex) => (prevIndex + 1) % textItemsA.length);
                setAnimateA('top-in');
            }, interval);
        }, animationInterval);

        const switchTextB = setInterval(() => {
            setAnimateB('bottom-out');
            setTimeout(() => {
                setCurrentIndexB((prevIndex) => (prevIndex + 1) % textItemsB.length);
                setAnimateB('bottom-in');
            }, interval);
        }, animationInterval);

        // To correctly offset B's animation without an immediate out animation, 
        // consider adjusting when and how this offset is applied.
        // For example, you might delay the start of B's interval itself
        // rather than initially setting it to animate out then in.
        // This could involve adjusting the timing of when `switchTextB` starts.

        return () => {
            clearInterval(switchTextA);
            clearInterval(switchTextB);
        };
    }, [textItemsA.length, textItemsB.length, interval, displayTime]);

    return (
        <div className="rolodexContainer">
            <div className={`rolodexText left ${animateA}`}>{textItemsA[currentIndexA]}</div>
            <div className={`rolodexText right ${animateB}`}>{textItemsB[currentIndexB]}</div>
        </div>
    );
};


export default DualRolodexText;

'use client';
import './Main.css';
import DualRolodexText from '../../ui/roladex/roladex';
import ExperienceSection from '../../ui/experience/experience';
import Image from 'next/image'
import avatar from '../../public/avatar.png'
import { FaEnvelope, FaGithub, FaLinkedin, FaFileAlt } from 'react-icons/fa';
import { WavyBackground } from '../../ui/wavy-background';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useBetterMediaQuery from '../../utils/tools/useBetterMediaQuery';

const MainPage: React.FC = () => {
    const isSmallScreen = useBetterMediaQuery('(max-width: 1000px)');


    const intro1 = "hey 👋👋 i am a recent grad from cmu specializing in ml, cs, and philosophy."
    const intro2 = "production experience with react, ruby on rails, sqlite, and aws. looking for work 🛠️"

    const experiences = [
        {
            role: 'Head Teaching Assistant',
            company: 'CMU 15-110',
            link: 'https://www.cs.cmu.edu/~15110/',
            technologies: ['Python', 'Tkinter', 'Pandas']
        },
        {
            role: 'SWE Intern',
            company: 'Snapsheet',
            link: 'https://www.snapsheetclaims.com/',
            technologies: ['React', 'Redux', 'Typescript', 'Ruby on Rails', 'Sqlite', 'AWS']
        },
        {
            role: 'SWE Intern',
            company: 'CSL Behring',
            link: 'https://www.csl.com/',
            technologies: ['Javascript', 'Python', 'MongoDB', 'AWS', 'Grafana']
        },
        {
            role: 'Teaching Assistant',
            company: 'CMU 15-110',
            link: 'https://www.cs.cmu.edu/~15110/',
            technologies: ['Python', 'Tkinter', 'Pandas']
        }
    ];
    const projects = [
        {
            role: '15-112 AI TA',
            company: 'Fine-tuned a variety of open-source LLMs including Llama3 using LoRA to create more helpful and instructive answers to student questions, augmented with RAG and ELO-based human evaluation',
            technologies: ['Python', 'HuggingFace', 'Unsloth', 'Chrome DB']
        },
        {
            role: 'Bustub',
            company: 'Created key features of a DBMS including a buffer pool manager, extendible hash index, and concurrent query executer',
            link: 'https://github.com/cmu-db/bustub',
            technologies: ['C++']
        },
        {
            role: "LoRA",
            company: "Implemented Low Rank Adaptation for GPT-2 to do semantic analysis on Rotten Tomatoes movie reviews",
            technologies: ['Python, PyTorch, HuggingFace, GPT-2']
        },
        {
            role: 'RoPE & GQA',
            company: 'Added Rotary Position Embeddings and GQA to minGPT to generate Shakespeare using PyTorch',
            technologies: ['Python', 'PyTorch', 'GPT-3']
        },
        {
            role: 'Mila Fejzo Art',
            link: 'https://www.milafejzo.art',
            company: 'Created a website for a local artist to showcase their work and sell their art',
            technologies: ['React', 'Strapi', 'Javascript', 'Netlify', 'Heroku']
        },
        {
            role: 'QG & QA System',
            company: 'Implemented system that answered/generated questions from a wikipedia article using a homemade neural net built with PyTorch and a variety of pretrained models',
            technologies: ['Python', 'PyTorch', 'HuggingFace']
        },
        {
            role: 'Single Hidden Layer Neural Net for Character Prediction',
            company: 'Trained on a subset of the MNISC character data set, fully implemented backpropagation for SGD from scratch',
            technologies: ['Python', 'Numpy']
        }
    ];

    return (
        <div className="main animate__animated animate__fadeIn">
            <WavyBackground>
                <div className="profile">
                    <Image className="profilePhoto" src={avatar} alt="Ezra" />
                    <div className=''>
                        <h1 className='name'>Ezra Boldizsar</h1>
                        <div>
                            <div className='icons'>
                                <a href="https://github.com/ezrajb722" target="_blank" rel="noopener noreferrer">
                                    <FaGithub size={20} color='white' />
                                </a>
                                <a href="https://linkedin.com/in/ezra-boldizsar" target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin size={20} color='white' />
                                </a>
                                <a
                                    href="/resume.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FaFileAlt size={20} color='white' />
                                </a>
                                <a href="mailto:ezra@boldizsar.me" target="_blank" rel="noopener noreferrer">
                                    <FaEnvelope size={20} color='white' />
                                </a>
                            </div>
                            <div style={{ textAlign: 'center', width: '400px' }}>
                                <DualRolodexText textItemsA={['fullstack engineer', 'senior@cmu', 'ml engineer']} textItemsB={['creating apps', 'studying ml and cs', 'fine-tuning LLMs']} interval={1000} displayTime={6000} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="about">
                        <h3 className='bio' >
                            {intro1}
                        </h3>
                        <h3 className='bio'>
                            {intro2}
                        </h3>
                    </div>
                    <div className="work">
                        {
                            isSmallScreen ? (
                                <Carousel showArrows={false} showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} interval={5000} transitionTime={1000} stopOnHover={true} swipeable={true} emulateTouch={true} dynamicHeight={true} useKeyboardArrows={true} centerMode={false} centerSlidePercentage={100} className="carousel">
                                    <div className="experience">
                                        <ExperienceSection experiences={experiences} title={'Work'} />
                                    </div>
                                    <div className="experience">
                                        <ExperienceSection experiences={projects} title={'Projects'} />
                                    </div>
                                </Carousel>
                            ) : (
                                <>
                                    <div className="experience">
                                        <ExperienceSection experiences={experiences} title={'Work'} />
                                    </div>
                                    <div>
                                        <ExperienceSection experiences={projects} title={'Projects'} />
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </WavyBackground>
        </div>
    );
};

export default MainPage;

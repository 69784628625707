// ExperienceSection.tsx
"use client";
import React, { useEffect, useRef, useState } from 'react';
import './Experience.css';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

interface Experience {
    role: string;
    company: string;
    technologies: string[];
    link?: string;
}

interface ExperienceSectionProps {
    title: string;
    experiences: Experience[];
}

const ExperienceSection: React.FC<ExperienceSectionProps> = ({ title, experiences }) => {
    const [isBottom, setIsBottom] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const checkScrollable = () => {
        const element = contentRef.current;
        if (element) {
            const scrollable = element.scrollHeight > element.clientHeight;
            setIsScrollable(scrollable);
        }
    };

    const onScroll = () => {
        const element = contentRef.current;
        if (element) {
            const isAtBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 1;
            setIsBottom(isAtBottom);
        }
    };

    useEffect(() => {
        const currentContentRef = contentRef.current;
        if (currentContentRef) {
            checkScrollable(); // Check if the content is scrollable on mount
            currentContentRef.addEventListener('scroll', onScroll);
        }
        return () => {
            if (currentContentRef) {
                currentContentRef.removeEventListener('scroll', onScroll);
            }
        };
    }, [experiences]); // Also re-check when experiences array changes

    return (
        <section>
            <div className='expMain'>
                <h3 className='sectionHeader'>{title}</h3>
                {isScrollable && (
                    <div>
                        <div className={`arrow-container ${isBottom ? 'hidden' : 'visible'}`}>
                            <FaArrowDown size={18} color='white' />
                        </div>
                        <div className={`arrow-container ${isBottom ? 'visible' : 'hidden'}`}>
                            <FaArrowUp size={18} color='white' />
                        </div>
                    </div>
                )}
                <div className="sectionContent" ref={contentRef} >
                    {experiences.map((experience, index) => (
                        <p key={index}>
                            {experience.link ? (
                                <a href={experience.link} className="roleLink title">{experience.role}</a>
                            ) : (
                                <span className='title'>{experience.role}</span>
                            )}
                            <span className="company">{` – ${experience.company} – `}</span>
                            {experience.technologies.map((tech, techIndex) => (
                                <span key={techIndex} className="technology">{tech}{techIndex < experience.technologies.length - 1 ? ', ' : ''}</span>
                            ))}
                        </p>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ExperienceSection;
